import React from "react";

import Layout from "../components/layout";

import * as styles from "./404.module.css";

export default function NotFound() {
  return (
    <Layout>
      <main>
        <div className={styles.error}>
          <h2>404: NOT FOUND</h2>
          <p>Sorry, I don't have anything here for you!</p>
          <p>
            Click <a href="/">here</a> to return to the main site.
          </p>
        </div>
      </main>
    </Layout>
  );
}
